<div class="notification-box" (click)="toggleNotificationMobile()">
    <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
    <span class="badge rounded-pill badge-secondary">4</span>
</div>
<div class="onhover-show-div notification-dropdown">
    <h6 class="f-18 mb-0 dropdown-title">Notificaciones</h6>
    <ul>
        <li class="b-l-primary border-4">
            <p>Inicio Reserva nº 9865442<span class="font-danger">10 min.</span></p>
        </li>
        <li class="b-l-primary border-4">
            <p>Inicio Reserva nº 4561896<span class="font-danger">2 h.</span></p>
        </li>
        <li class="b-l-primary border-4">
            <p>Inicio Reserva nº 5224799<span class="font-danger">1 día(s)</span></p>
        </li>
        <li class="b-l-secondary border-4">
            <p>Pagos pendientes<span class="font-secondary">2 día(s)</span></p>
        </li>
        <li class="b-l-success border-4">
            <p>Adelanto recibido<span class="font-success">1 semana(s)</span></p>
        </li>
        <!-- <li class="b-l-warning border-4">
      <p>Delivery Complete<span class="font-warning">6 hr</span></p>
    </li> -->
        <!-- <li><a class="f-w-700" href="#">Check all</a></li> -->
    </ul>
</div>